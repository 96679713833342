<template>
  <Page>
    <template v-slot:content>
      <SectionHeader
        :title="$t('pages.PolicyAndPrivacy.title')"
        type="primary"
        titleSize="large"
      ></SectionHeader>
      <div id="DIV_1">
        <div id="DIV_2">
          <div id="DIV_3">
            <p id="P_4">
              A HYUNDAI PORTUGAL respeita a privacidade individual e valoriza a
              confiança<br id="BR_5" />
              dos seus clientes e parceiros, assumindo com grande seriedade as
              suas responsabilidades no que se refere à segurança destes mesmos
              dados.
            </p>
            <p id="P_6">
              Neste contexto, damos a conhecer a política de privacidade da
              HYUNDAI PORTUGAL, que explica que dados pessoais recolhemos, para
              que finalidades os podemos usar, como os tratamos, com quem os
              partilhamos, durante quanto tempo os conservamos bem como as
              formas de entrar em contacto connosco e exercer os seus direitos.
            </p>
            <p id="P_7"></p>
            <p id="P_8">
              <b id="B_9"
                >1) Quem é o responsável pelo tratamento dos seus dados?</b
              >
            </p>
            <p id="P_10">
              A HYUNDAI PORTUGAL, S.A., NIPC 500820384, com sede em Lake Towers,
              Ed. D, 5.º C, Rua Daciano Marques, 245, 4400-671 Vila Nova de
              Gaia, é a entidade responsável pelo tratamento dos seus dados.
            </p>
            <p id="P_11">
              Ainda que desenvolvam a sua atividade utilizando a marca HYUNDAI,
              os concessionários/ reparadores autorizados são entidades
              juridicamente autónomas. Assim, os concessionários/ reparadores
              autorizados serão também responsáveis pelo tratamento dos seus
              dados nos casos em que haja necessidade de transmitir os seus
              dados a tais entidades.
            </p>
            <p id="P_12">
              Por outro lado, os concessionários/ reparadores autorizados podem
              também proceder a recolhas autónomas dos seus dados diretamente
              junto de si e ter as suas próprias políticas de privacidade, razão
              pela qual deverá contactar<br id="BR_13" />
              tais entidades em caso de alteração ou questão relativa à
              utilização que aqueles fazem dos seus dados. Em tais casos, a
              HYUNDAI PORTUGAL não terá qualquer responsabilização ou
              intervenção na forma como tais entidades procedem ao tratamento
              dos seus dados pessoais.
            </p>
            <p id="P_14">
              Porque assumimos com seriedade a importância da privacidade dos
              dados que tratamos, a HYUNDAI PORTUGAL tem um Encarregado de
              Proteção de Dados, o qual pode ser contactado através do endereço
              <a href="mailto:epd@hyundai.pt" id="A_15">epd@hyundai.pt</a>.
            </p>
            <p id="P_16"></p>
            <p id="P_17">
              <b id="B_18">2) Que informação recolhemos sobre si?</b>
            </p>
            <p id="P_19">
              A HYUNDAI PORTUGAL apenas recolhe e trata os dados pessoais que
              sejam diretamente fornecidos por si, nomeadamente seja através do
              preenchimento de algum formulário de contacto, seja através da<br
                id="BR_20"
              />
              aquisição de um produto ou serviço HYUNDAI junto da HYUNDAI
              PORTUGAL ou dos seus concessionários/ reparadores autorizados,
              que, em geral, serão, consoante o caso, os seguintes:
            </p>
            <table id="TABLE_21">
              <tbody id="TBODY_22">
                <tr id="TR_23">
                  <td id="TD_24">
                    <b id="B_25">CATEGORIA DE DADOS</b>
                  </td>
                  <td id="TD_26">
                    <b id="B_27">EXEMPLOS</b>
                  </td>
                </tr>
                <tr id="TR_28">
                  <td id="TD_29">
                    <b id="B_30">Dados de identificação</b>
                  </td>
                  <td id="TD_31">
                    Nome, números e datas de validade de documentos de<br
                      id="BR_32"
                    />
                    identificação civil (e respetivas imagens), entre outros.
                  </td>
                </tr>
                <tr id="TR_33">
                  <td id="TD_34">
                    <b id="B_35">Dados de contacto</b>
                  </td>
                  <td id="TD_36">
                    Morada, Telemóvel, E-mail, entre outros.
                  </td>
                </tr>
                <tr id="TR_37">
                  <td id="TD_38">
                    <b id="B_39">Dados relativos a situação profissional</b>
                  </td>
                  <td id="TD_40">
                    Profissão, situação profissional, entre outros.
                  </td>
                </tr>
                <tr id="TR_41">
                  <td id="TD_42">
                    <b id="B_43">Dados relativos a situação pessoal</b>
                  </td>
                  <td id="TD_44">
                    Data de nascimento, estado civil, agregado familiar,
                    entre<br id="BR_45" />
                    outros.
                  </td>
                </tr>
                <tr id="TR_46">
                  <td id="TD_47">
                    <b id="B_48">Dados económicos e transacionais</b>
                  </td>
                  <td id="TD_49">
                    Dados Bancários, entre outros.<b id="B_50"></b>
                  </td>
                </tr>
                <tr id="TR_51">
                  <td id="TD_52">
                    <b id="B_53">Dados de histórico cliente</b>
                  </td>
                  <td id="TD_54">
                    Respostas a Inquéritos de Satisfação, Data da Encomenda,<br
                      id="BR_55"
                    />
                    Prazo do Contrato, Preço, Histórico de Reclamações, entre<br
                      id="BR_56"
                    />
                    outros.<b id="B_57"></b>
                  </td>
                </tr>
                <tr id="TR_58">
                  <td id="TD_59">
                    <b id="B_60">Dados de utilização da viatura</b>
                  </td>
                  <td id="TD_61">
                    Número de quilómetros, registo de manutenções e<br
                      id="BR_62"
                    />
                    intervenções, entre outros.<b id="B_63"></b>
                  </td>
                </tr>
                <tr id="TR_64">
                  <td id="TD_65">
                    <b id="B_66"
                      >Dados de Aplicação, Internet e Redes Sociais</b
                    >
                  </td>
                  <td id="TD_67">
                    Localização, utilização das redes sociais da HYUNDAI<br
                      id="BR_68"
                    />
                    PORTUGAL, entre outros.
                  </td>
                </tr>
              </tbody>
            </table>
            <p id="P_69">
              <b id="B_70"
                >3) Como e porque utilizamos os seus dados pessoais?</b
              >
            </p>
            <p id="P_71">
              Usamos os seus dados pessoais para:
            </p>
            <table id="TABLE_72">
              <tbody id="TBODY_73">
                <tr id="TR_74">
                  <td id="TD_75">
                    <strong id="STRONG_76">FINALIDADE</strong>
                  </td>
                  <td id="TD_77">
                    <strong id="STRONG_78">EXEMPLOS</strong>
                  </td>
                </tr>
                <tr id="TR_79">
                  <td id="TD_80">
                    <strong id="STRONG_81"
                      >1. Execução e cumprimento das nossas obrigações
                      pré-contratuais e contratuais.</strong
                    >
                  </td>
                  <td id="TD_82">
                    <ul id="UL_83">
                      <li id="LI_84">
                        Responder às suas solicitações
                      </li>
                      <li id="LI_85">
                        Garantir a marcação de serviços e intervenções
                      </li>
                      <li id="LI_86">
                        Execução da garantia contratual
                      </li>
                      <li id="LI_87">
                        Entre outros
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr id="TR_88">
                  <td id="TD_89">
                    <strong id="STRONG_90"
                      >2. Comunicar e gerir a nossa relação contratual
                      consigo</strong
                    >
                  </td>
                  <td id="TD_91">
                    <ul id="UL_92">
                      <li id="LI_93">
                        Contactar consigo por motivos administrativos e/ou
                        operacionais, assegurando que gerimos corretamente a sua
                        relação connosco e resolvemos a sua solicitação e/ou
                        pedido.
                      </li>
                      <li id="LI_94">
                        Gravação de chamadas para monitorização da qualidade de
                        serviço
                      </li>
                      <li id="LI_95">
                        Entre outros
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr id="TR_96">
                  <td id="TD_97">
                    <strong id="STRONG_98"
                      >3. Personalizar e melhorar a sua experiência de
                      cliente</strong
                    >
                  </td>
                  <td id="TD_99">
                    <ul id="UL_100">
                      <li id="LI_101">
                        Contactar consigo para a realização de Inquéritos de
                        Satisfação sobre os nossos serviços. Entendemos que
                        temos um interesse legítimo no tratamento dos seus dados
                        pessoais para esta finalidade, a qual entendemos ser
                        igualmente benéfica para si porquanto nos permite melhor
                        servi-lo e garantir-lhe a maior satisfação possível
                        enquanto nosso Cliente. A qualquer momento, pode nos
                        informar de que não pretende mais ser contactado para
                        estes efeitos.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr id="TR_102">
                  <td id="TD_103">
                    <strong id="STRONG_104">4. Marketing</strong>
                  </td>
                  <td id="TD_105">
                    <ul id="UL_106">
                      <li id="LI_107">
                        Caso seja nosso Cliente, usaremos os seus dados pessoais
                        para lhe fornecer, por E-mail, notícias nossas, tais
                        como novas ofertas, novas campanhas e novos veículos e
                        serviços HYUNDAI de que poderá gostar. Consideramos ter
                        um interesse legítimo no envio desta informação, uma vez
                        que será igualmente benéfico para si, permitindo
                        melhorar a sua experiência e aceder a informação que
                        acreditamos ser do seu total interesse.
                      </li>
                    </ul>
                    <ul id="UL_109">
                      <li id="LI_110">
                        Caso não seja nosso Cliente, usaremos os seus dados
                        pessoais para o informar de ofertas de que poderá
                        gostar, apenas quando nos tenha dado o seu expresso
                        consentimento para o efeito, por exemplo, quando
                        preenche um formulário de contacto e assinala tal opção.
                      </li>
                    </ul>
                    Para estes efeitos, poderemos ainda proceder a uma análise
                    do seu perfil de utilizador ou cliente, nomeadamente através
                    do seu histórico de navegação e/ou histórico de compras,
                    para determinar quais são as suas preferências e, assim,
                    enviar-lhe informação personalizada. Em momento algum,
                    partilharemos os seus dados pessoais com outras entidades
                    terceiras para efeitos de marketing, exceto se tivermos o
                    seu expresso e explícito consentimento. Caso não queira
                    receber comunicações de marketing da nossa parte, pode
                    dizer-nos isso mesmo a qualquer momento, ao clicar na
                    ligação para o efeito na parte inferior de qualquer E-mail
                    de marketing que receber da HYUNDAI PORTUGAL. Se preferir,
                    pode sempre expressar a sua preferência em não receber
                    comunicações de marketing para o E-mail
                    <a href="mailto:privacidade@hyundai.pt" id="A_114"
                      >privacidade@hyundai.pt</a
                    >.
                  </td>
                </tr>
                <tr id="TR_115">
                  <td id="TD_116">
                    <strong id="STRONG_117"
                      >5. Cumprirmos as nossas obrigações legais</strong
                    >
                  </td>
                  <td id="TD_118">
                    <ul id="UL_119">
                      <li id="LI_120">
                        Prestar-lhe assistência no âmbito da garantia legal e
                        para realização de campanhas técnicas que visam a sua
                        segurança e a do seu automóvel, bem como outras
                        obrigações legais que possam recair sobre nós.
                      </li>
                      <li id="LI_121">
                        Resposta a entidades judiciais, de regulação e de
                        supervisão.
                      </li>
                      <li id="LI_122">
                        Entre outros
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr id="TR_123">
                  <td id="TD_124">
                    <strong id="STRONG_125"
                      >6. Gerir o seu registo como utilizador</strong
                    >
                  </td>
                  <td id="TD_126">
                    <ul id="UL_127">
                      <li id="LI_128">
                        Caso se registe como utilizador da Loja Online HYUNDAI,
                        da plataforma myHYUNDAI e/ou outra aplicação, área
                        reservada ou plataforma propriedade da HYUNDAI PORTUGAL,
                        necessitamos tratar os seus dados para o identificar
                        como utilizador em tais plataformas e dar-lhe a
                        possibilidade de aceder às diversas funcionalidades,
                        produtos e serviços que estão ao dispor como utilizador
                        registado.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr id="TR_129">
                  <td id="TD_130">
                    <strong id="STRONG_131"
                      >7. Controlo da Segurança Física</strong
                    >
                  </td>
                  <td id="TD_132">
                    <ul id="UL_133">
                      <li id="LI_134">
                        Videovigilância nas nossas instalações, quando
                        aplicável.
                      </li>
                      <li id="LI_135">
                        Entre outros
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr id="TR_136">
                  <td id="TD_137">
                    <strong id="STRONG_138">8. Gestão de Contencioso</strong>
                  </td>
                  <td id="TD_139">
                    <ul id="UL_140">
                      <li id="LI_141">
                        Cobrança judicial e extrajudicial.
                      </li>
                      <li id="LI_142">
                        Gestão de outros conflitos.
                      </li>
                      <li id="LI_143">
                        Entre outros
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr id="TR_144">
                  <td id="TD_145">
                    <strong id="STRONG_146"
                      >9. Gestão Contabilística, Fiscal e Administrativa</strong
                    >
                  </td>
                  <td id="TD_147">
                    <ul id="UL_148">
                      <li id="LI_149">
                        Contabilidade e faturação.
                      </li>
                      <li id="LI_150">
                        Informação Fiscal, incluindo envio de informação à
                        Autoridade Tributária.
                      </li>
                      <li id="LI_151">
                        Entre outros.
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p id="P_152">
              A HYUNDAI PORTUGAL compromete-se consigo a não utilizar a
              informação relativa aos seus dados pessoais para nenhuma
              finalidade que não sejam as anteriormente descritas.
            </p>
            <p id="P_153"></p>
            <p id="P_154">
              <b id="B_155"
                >4) De que forma mantemos os seus dados pessoais seguros e
                durante quanto tempo conservaremos os seus dados?</b
              >
            </p>
            <p id="P_156">
              Implementámos medidas técnicas e organizativas para proteger os
              seus dados pessoais contra a destruição, acidental ou ilícita, a
              perda acidental, a alteração, a difusão ou o acesso não autorizado
              aos mesmos.
            </p>
            <p id="P_157">
              O prazo de conservação dos seus dados dependerá das finalidades
              para as quais os tratamos, ou seja, tratamos os seus dados durante
              o tempo estritamente necessário para cumprir a finalidade
              correspondente e, eventualmente, conservamos posteriormente os
              mesmos, devidamente guardados e protegidos, durante o tempo em que
              puderem surgir responsabilidades decorrentes do tratamento,
              cumprimento a legislação vigente em cada momento.
            </p>
            <p id="P_158">
              Quanto às nossas comunicações de Marketing, informamos o seguinte:
            </p>
            <ul id="UL_159">
              <li id="LI_160">
                Caso nos tenha dado o seu consentimento para efeitos de
                Marketing, os seus dados pessoais irão ser conservados até ao
                prazo máximo de 24 meses partir da recolha do seu consentimento
                ou, caso tenha adquirido algum produto e/ou serviço HYUNDAI,
                pelo período de 60 meses a contar da concessão do seu
                consentimento;
              </li>
              <li id="LI_161">
                Caso seja nosso cliente e havendo interesse legítimo para o
                contacto via E-mail, iremos guardar os seus dados até nos dizer
                que não mais o pretende após clicar na ligação para o efeito na
                parte inferior de qualquer E-mail<br id="BR_162" />
                de marketing que receber da HYUNDAI PORTUGAL.
              </li>
            </ul>
            <p id="P_163">
              No caso de efetuar algum pagamento através de alguma das
              plataformas da HYUNDAI, o seu pagamento será redirecionado para o
              nosso fornecedor responsável pela gestão de pagamentos e que, no
              momento, será devidamente<br id="BR_164" />
              identificado, entidade que será Responsável pelo Tratamento dos
              seus dados pessoais para esta finalidade especifica de pagamento
              de um produto/serviço. Toda e qualquer operação de seleção do meio
              de pagamento a utilizar e introdução dos dados inerentes ao
              processo de pagamento está devidamente protegida. A informação em
              causa é encriptada e gerida com as mais avançadas ferramentas de
              segurança disponíveis.
            </p>
            <p id="P_165">
              Caso necessite de esclarecimentos adicionais sobre as medidas de
              segurança implementadas bem como sobre os prazos concretos de
              conservação dos dados, agradecemos contacto para o E-mail
              <a href="mailto:privacidade@hyundai.pt" id="A_166"
                >privacidade@hyundai.pt</a
              >.
            </p>
            <p id="P_167"></p>
            <p id="P_168">
              <b id="B_169">5) Com quem partilhamos os seus dados pessoais?</b>
            </p>
            <p id="P_170">
              Os seus dados pessoais são tratados com a máxima confidencialidade
              e são utilizados única e exclusivamente para as finalidades
              identificadas.
            </p>
            <p id="P_171">
              A HYUNDAI PORTUGAL garante que nunca procederá à venda, empréstimo
              ou cedência dos seus dados pessoais a terceiros, sem que haja o
              seu expresso e explícito consentimento para o efeito.
            </p>
            <p id="P_172">
              Os seus dados pessoais, para efeitos de garantirmos a assistência
              ao cliente e/ou potencial cliente, poderão ser partilhados com o
              concessionário/ reparador autorizado anteriormente por si
              escolhido, o concessionário da sua última compra de uma viatura
              HYUNDAI, o concessionário/ reparador autorizado do seu último
              serviço/ reparação ou o concessionário/ reparador autorizado
              geograficamente mais próximo da sua área de residência.
            </p>
            <p id="P_173">
              Os seus dados pessoais poderão igualmente ser transmitidos à
              HYUNDAI MOTOR EUROPE GmbH, com sede na Alemanha, na qualidade de
              representante e fabricante dos produtos HYUNDAI no Continente
              Europeu.
            </p>
            <p id="P_174">
              Por outro lado, para garantirmos o devido seguimento às suas
              solicitações bem como lhe garantirmos a melhor assistência
              possível, os seus dados pessoais poderão ser partilhados com os
              nossos fornecedores ou prestadores de serviços, por exemplo:
            </p>
            <ul id="UL_175">
              <li id="LI_176">
                Instituições Financeiras
              </li>
              <li id="LI_177">
                Prestadores de serviços tecnológicos
              </li>
              <li id="LI_178">
                Prestadores de serviços relacionados com o apoio ao cliente.
              </li>
            </ul>
            <p id="P_179">
              Sendo certo que a HYUNDAI PORTUGAL garante que tais entidades se
              encontram igualmente munidas de medidas técnicas e organizativas
              para garantir a total proteção dos seus dados pessoais e que os
              mesmos apenas tratarão dos<br id="BR_180" />
              seus dados para cumprimento integral das finalidades inerentes à
              execução do contrato.
            </p>
            <p id="P_181">
              Caso os seus dados pessoais tenham que ser partilhados com
              entidades que não estejam localizadas no Espaço Económico Europeu,
              a HYUNDAI PORTUGAL garante que as mesmas se encontram obrigadas a
              respeitar a legislação europeia relativa à proteção de dados
              pessoais, nomeadamente através da celebração de acordos de
              transferências internacionais de dados com cláusulas contratuais
              tipo para o efeito.
            </p>
            <p id="P_182">
              Caso necessite de esclarecimentos adicionais sobre os concretos
              destinatários dos seus dados e as salvaguardas específicas que
              foram aplicadas à transferência dos seus dados, agradecemos
              contacto para o E-mail
              <a href="mailto:privacidade@hyundai.pt" id="A_183"
                >privacidade@hyundai.pt</a
              >.
            </p>
            <p id="P_184"></p>
            <p id="P_185">
              <b id="B_186">6) Quais são os seus direitos?</b>
            </p>
            <p id="P_187">
              Tem o direito de, sempre que quiser e gratuitamente, pedir à
              HYUNDAI PORTUGAL para:
            </p>
            <ul id="UL_188">
              <li id="LI_189">
                aceder aos dados que nos indicou
              </li>
              <li id="LI_190">
                pedir a retificação dos seus dados
              </li>
              <li id="LI_191">
                pedir o apagamento dos seus dados
              </li>
              <li id="LI_192">
                pedir a limitação do tratamento dos seus dados
              </li>
              <li id="LI_193">
                opor-se ao tratamento dos seus dados
              </li>
              <li id="LI_194">
                solicitar a portabilidade dos seus dados para entidade por si
                indicada.
              </li>
            </ul>
            <p id="P_195">
              Se nos facultou o seu consentimento para o tratamento dos seus
              dados para qualquer finalidade, tem direito também a retirá-lo em
              qualquer momento, através das formas já referidas na presente
              Política de Privacidade.
            </p>
            <p id="P_196">
              Para exercer qualquer um dos direitos referidos ou para qualquer
              esclarecimento adicional sobre o tratamento dos seus dados
              pessoais pela HYUNDAI PORTUGAL, basta enviar um E-mail para
              <a href="mailto:privacidade@hyundai.pt" id="A_197"
                >privacidade@hyundai.pt</a
              >.
            </p>
            <p id="P_198">
              Caso exerça algum dos direitos mencionados, a HYUNDAI PORTUGAL irá
              proceder à sua análise, garantindo uma resposta no prazo máximo de
              1 (um) mês.
            </p>
            <p id="P_199">
              <a name="_Hlk4517963" id="A_200"></a>Note-se, porém, que caso
              exista norma ou obrigação legalmente imposta que se sobreponha a
              estes direitos, a HYUNDAI PORTUGAL responderá a impossibilidade de
              executar o pedido, indicando o respetivo fundamento.
            </p>
            <p id="P_201"></p>
            <p id="P_202">
              <b id="B_203">7) Como fazer uma reclamação?</b>
            </p>
            <p id="P_204">
              Caso não esteja satisfeito com a forma com que os seus dados
              pessoais se encontram a ser tratados pela HYUNDAI PORTUGAL,
              agradecemos o seu contacto para
              <a href="mailto:privacidade@hyundai.pt" id="A_205"
                >privacidade@hyundai.pt</a
              >.
            </p>
            <p id="P_206">
              No caso de se manter insatisfeito, tem o direito de apresentar uma
              reclamação diretamente à Comissão Nacional de Proteção de Dados,
              conforme dados infra:
            </p>
            <p id="P_207">
              Comissão Nacional de Proteção de Dados – CNPD
            </p>
            <p id="P_208">
              Tel: +351 213928400
            </p>
            <p id="P_209">
              Fax: +351 213976832
            </p>
            <p id="P_210">
              E-mail:
              <a href="mailto:geral@cnpd.pt" id="A_211">geral@cnpd.pt</a>
            </p>
            <p id="P_212"></p>
            <p id="P_213">
              <b id="B_214">8) O que são cookies?</b>
            </p>
            <p id="P_215">
              Os “cookies” são pequenas etiquetas de software que são
              armazenadas no seu computador ou smartphone através do navegador
              (browser) ou aplicação, retendo apenas informação relacionada com
              as suas preferências, não incluindo, como tal, os seus dados
              pessoais.
            </p>
            <p id="P_216">
              A HYUNDAI PORTUGAL utiliza nos seus websites, apps e redes sociais
              cookies que permitem melhorar o desempenho e a experiência de
              navegação dos seus Clientes/ Utilizadores, aumentando, por um
              lado, a rapidez e eficiência de resposta e, por outro lado,
              eliminando a necessidade de introduzir repetidamente as mesmas
              informações.
            </p>
            <p id="P_217">
              Os cookies podem ser:
            </p>
            <ul id="UL_218">
              <li id="LI_219">
                <b id="B_220">Essenciais</b>, permitindo que navegue no website
                e utilize as suas aplicações, bem como aceder a áreas seguras e
                reservadas, permitindo, através da recolha de informação anónima
                ajudar a melhorar a forma como os websites e aplicações
                funcionam bem como entender o que interesse aos nossos
                utilizadores. Usamos estes cookies, nomeadamente, para guardar a
                informação da configuração de viaturas, relembrar a informação
                introduzida em formulários e registo de visitas para tratamento
                estatístico. A aceitação destes cookies é uma condição para uso
                dos nossos websites e aplicações.<b id="B_221"></b>
              </li>
              <li id="LI_222">
                <b id="B_223">Funcionais</b>, permitindo relembrar as
                preferências do utilizador relativamente à navegação nos
                websites ou utilização de aplicações, não necessitando, assim,
                de os reconfigurar e personalizar cada vez que os visita e<br
                  id="BR_224"
                />
                utiliza. Usamos estes cookies, nomeadamente, para verificar se o
                website se encontra com informação consistente, procurar o
                concessionário/ reparador autorizado mais próximo, relembrar se
                já perguntamos se pretende fazer o seu registo num formulário.
                Pode, a qualquer momento, controlar ou não se estes cookies são
                utilizados através das definições do seu browser ou aplicação.
              </li>
              <li id="LI_225">
                <b id="B_226">Publicidade</b>, permitindo direcionar publicidade
                em função dos interesses de cada utilizador, por forma a
                direcionar campanhas publicitárias de acordo com os hábitos de
                navegação, permitindo ainda fazer a ligação a redes sociais,
                tais como Facebook, Youtube, Instagram, entre outros, por forma
                a direcionar a publicidade também nesses websites. Utilizamos
                estes cookies, nomeadamente, para partilhar informação e
                questioná-lo se<br id="BR_227" />
                pretende um test-drive ou receber um catálogo, permitir
                partilhar alguns conteúdos nas redes sociais, bem como
                questioná-lo acerca da publicidade ou campanha existente nos
                nossos websites. Pode, a qualquer momento, controlar ou não se
                estes cookies são utilizados através das definições do seu
                browser ou aplicação.<b id="B_228"></b>
              </li>
            </ul>
            <p id="P_229">
              Caso necessite de esclarecimentos adicionais sobre as definições e
              a nossa política de cookies, agradecemos contacto para o E-mail
              <a href="mailto:privacidade@hyundai.pt" id="A_230"
                >privacidade@hyundai.pt</a
              >.
            </p>
            <p id="P_231"></p>
            <p id="P_232">
              <b id="B_233">9) Perguntas e comentários?</b>
            </p>
            <p id="P_234">
              Todas as perguntas, comentários e pedidos relativamente à
              privacidade e aos seus dados pessoais são sempre bem-vindos,
              devendo ser enviados para
              <a href="mailto:privacidade@hyundai.pt" id="A_235"
                >privacidade@hyundai.pt</a
              >.
            </p>
            <p id="P_236">
              Por forma a garantirmos que os dados que detemos sobre si se
              mantêm atualizados, agradecemos que, em caso de modificação, nos
              contacte também para
              <a href="mailto:privacidade@hyundai.pt" id="A_237"
                >privacidade@hyundai.pt</a
              >
              para procedermos à sua atualização.
            </p>
            <p id="P_238"></p>
            <p id="P_239">
              <b id="B_240">10) Alterações à Política de Privacidade?</b>
            </p>
            <p id="P_241">
              A HYUNDAI PORTUGAL reserva o direito de, a qualquer momento,
              proceder a modificações ou atualizações à presente Política de
              Privacidade, sendo essas alterações devidamente atualizadas na
              presente Plataforma.
            </p>
            <p id="P_242">
              HYUNDAI PORTUGAL
            </p>
          </div>
        </div>
      </div>
    </template>
  </Page>
</template>

<script>
import { Page } from "../../templates";
import SectionHeader from "@/components/molecules/SectionHeader/SectionHeader";

export default {
  name: "PolicyPrivacyPage",
  components: {
    Page,
    SectionHeader
  },
  metaInfo() {
    return {
      title: this.$t("meta.policy.title"),
      meta: [
        {
          name: "description",
          content: this.$t("meta.policy.description")
        },
        {
          name: "keywords",
          content: this.$t("meta.policy.keywords")
        },
        {
          property: "og:title",
          content: this.$t("meta.policy.title")
        },
        {
          property: "og:description",
          content: this.$t("meta.policy.description")
        },
        {
          property: "og:url",
          content: window.location.href
        },
        {
          property: "og:image",
          content: this.$t("meta.policy.image")
        }
      ]
    };
  }
};
</script>

<style scoped lang="css" src="./PolicyPrivacyPage.css"></style>
